import { useNavigation } from "@remix-run/react";
import React from "react";

const GlobalSpinnerLoader: React.FC = () => {
  const navigation = useNavigation();

  const active = navigation.state !== "idle";
  return (
    active && (
      <div
        role="progressbar"
        aria-valuetext={active ? "Loading" : undefined}
        aria-hidden={!active}
        className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-40  pointer-events-none z-50 p-4`}
      >
        <div className="p-6 text-center">
          <div className="w-16 h-16 border-4 border-gray-200 border-t-4 border-t-primary rounded-full animate-spin mx-auto mb-4"></div>
        </div>
      </div>
    )
  );
};

export default GlobalSpinnerLoader;
