import {
  json,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteLoaderData,
} from "@remix-run/react";
import type { LinksFunction } from "@remix-run/node";
import styles from "./tailwind.css?url"
import GlobalSpinnerLoader from "./components/loader/GlobalSpinnerLoader";
import ErrorBoundary from "./components/error/ErrorBoundary";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: styles },
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "192x192",
    href: "https://ik.imagekit.io/u7uktwxu0/Business%20Icons/fevicon-business%20console.svg",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "512x512",
    href: "https://ik.imagekit.io/u7uktwxu0/Business%20Icons/fevicon-business%20console.svg",
  },
];

interface LoaderData {
  theme: "dark" | "light";
}

export const loader = async ({ request }: { request: Request }) => {
  const theme = request?.headers?.get("sec-ch-prefers-color-scheme") || "dark";
  return json({ theme });
};

export function Layout({ children }: { children: React.ReactNode }) {

  const loader = useRouteLoaderData<LoaderData>("root");

  const theme = loader?.theme || "dark";

  return (
    <html lang="en" className={theme}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className={theme === "dark" ? "bg-white text-black dark:bg-gray-900 dark:text-black" : ""}>
        <GlobalSpinnerLoader />
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default function App() {
  return <>
    <Outlet />

  </>
}


export { ErrorBoundary };

